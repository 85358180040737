import { richText, stripRichTextWrapperTag } from '~/utils/html';
import {
  advantagesPushAdapter,
  getConfirmation,
} from '~/components/form-push/adapter';
import { getEkomiData, getRating } from '~/utils/ekomi';
import { marketLinks, productLinks, routerLinks } from '~/utils/fetch-links';
import mergeI18n from '~/utils/merge-i18n';
import formsI18n from '~/i18n/messages/fr-fr.forms.json';
import api from '~/components/form-block/api';
import pipedriveI18n from '~/i18n/messages/fr-fr.pipedrive.json';

const INPUTS_STEPS = {
  civilite: 'civility',
  nom: 'name',
  'adresse email': 'email',
  telephone: 'phone',
  prenom: 'firstname',
  'code postal': 'postalcode',
  '(DO) futur projet': 'pipedrive_futur_projet',
  '(DO) suivi integral maitre': 'pipedrive_a_maitre',
  '(DO) estimation cout total': 'pipedrive_cout_estime',
  '(RCD) activites exercees': 'rcd_activites_exercees',
  '(RCD) chiffre d affaires': 'rcd_chiffre_affaires',
  '(RCD) historique rcd': 'rc_decennale',
  '(RCD rich) certification QUALIPV': 'rcd_qualipv_certification',
  '(RCD rich) experience activites': 'rcd_a_experience',
  '(RCD rich) annees experience activites': 'rcd_dannee_experience',
  '(RCD) date creation': 'rcd_date_creation',
  '(RCD) code postal entreprise': 'rcd_code_postal',
  '(RCD) activite principale': 'rcd_activite_principale',
};

export const getInputName = (name) => INPUTS_STEPS[name] || name;

export const getInput = (inputs, name) => {
  const baseInput = inputs.find((input) => input.name === name);
  if (!baseInput) return;
  const input = { ...baseInput, ...baseInput?.field };
  input.title = input.label;

  if (!input.validator) {
    delete input.validator;
  } else {
    input.validator = { validate: input.validator };
  }

  if (!input.type) {
    input.type = 'text';
  }

  if (input.validator) {
    if (name === 'effectiveDate') {
      input.validator.validate = 'effectiveDate';
    } else if (name === 'birthDate') {
      input.validator.validate = 'matureAge';
    }
  }

  if (input.options && input.type === 'radio') {
    input.inputs = [];

    Object.keys(input.options).map((optionKey) => {
      input.inputs.push(input.options[optionKey]);
    });

    delete input.options;
  }

  delete input.field;
  return input;
};

export const getSteps = (data, steps, apiDataInputs) =>
  steps.map((step, index) => {
    const isSubmitStep = steps.length - 1 === index;

    const inputs = step.items
      .map((item) => getInput(apiDataInputs, getInputName(item.input)))
      .filter((input) => input !== undefined);
    return {
      labelStep: step.primary.step_label,
      primaryCta: {
        label: isSubmitStep ? data.submit_label : data.next_label,
        variant: 'primary',
      },
      secondaryCta: { label: data.previous_label, variant: 'secondary' },
      intro: {
        title: stripRichTextWrapperTag(step.primary.step_title),
      },
      inputs,
      isSubmitStep,
    };
  });

export const formStepsSerializer = async (id, data, context) => {
  const i18n = context.$i18n;
  const { $prismic } = context;

  const { data: formData } = await $prismic.client.getByID(id, {
    fetchLinks: [...routerLinks, ...productLinks, ...marketLinks],
  });

  if (!formData) return null;

  mergeI18n(i18n, formsI18n);

  const apiData = api(formData, i18n, 'form_steps');

  if (formData.form_api === 'pipedrive') {
    Object.keys(formsI18n).forEach((key) => {
      if (key in pipedriveI18n) {
        formsI18n[key] = pipedriveI18n[key];
      }
    });
    apiData.form[0].field.options =
      pipedriveI18n.forms.entries.civility.options;
  }

  const stepsSlice = data.body.filter(
    (slice) => slice.slice_type === 'form_step'
  );

  const steps = getSteps(data, stepsSlice, apiData.form);

  const hiddenFields = apiData.form
    .map((item) => item)
    .filter((input) => input?.field?.type === 'hidden');

  if (!steps) return;
  return {
    type: formData.form_api,
    urlType: data?.type,
    withSteps: true,
    form: {
      title: stripRichTextWrapperTag(formData.form_title),
      intro: richText(formData.form_intro),
      labelStep: formsI18n.labelStep,
      steps,
      hiddenFields: hiddenFields,
      mention: richText(formData.form_mention),
    },
  };
};

export const getContent = async (primary, data, context) => {
  if (!primary) {
    return null;
  }

  const introSupertitle = richText(primary.form_intro_supertitle);
  const introTitle = stripRichTextWrapperTag(primary.form_intro_title);
  const introDescription = richText(primary.form_intro_description);
  const hasIntro = introSupertitle || introTitle || introDescription;

  const ekomiAccount = data.market?.data?.ekomi_account || null;
  const withEkomiRating = primary.with_ekomi_rating;
  let ekomiData, ekomiInfobulle;

  if (withEkomiRating) {
    ekomiInfobulle = primary.ekomi_rating_infobulle;
    ekomiData = await getEkomiData(ekomiAccount, 'reviews', context);
  }

  const hasAdvantagesPush = primary.hasadvantagespush;

  return {
    overlap: primary.form_push_overlap,
    ratingsInfoData:
      withEkomiRating && ekomiData
        ? {
            rating: ekomiData.average_rating,
            ratingLabel: `<b>${getRating(
              ekomiData.average_rating,
              context.i18n
            )}/5</b> (${ekomiData.review_count} avis)`,
            infobulle: ekomiInfobulle
              ? `<button data-tooltip="${ekomiInfobulle}">i</button>`
              : null,
          }
        : null,
    intro: hasIntro
      ? {
          supertitle: introSupertitle,
          title: introTitle,
          description: introDescription,
        }
      : null,
    title: stripRichTextWrapperTag(primary.form_push_title),
    description: richText(primary.form_push_content),
    hasAdvantagesPush,
    advantagesPushData: hasAdvantagesPush
      ? advantagesPushAdapter(
          primary.advantagespush,
          context.$enhancedLinkSerializer
        )
      : null,
  };
};

export default async ({ primary }, _data, context) => {
  const formStepsId = primary?.form?.id;
  const { $enhancedLinkSerializer, $prismic } = context;

  if (!formStepsId) {
    return null;
  }

  const request = await $prismic.client.getByID(formStepsId);

  if (!request?.id) {
    return null;
  }

  const { data } = request;

  const content = await getContent(primary, _data, context);

  const confirmation = await getConfirmation(
    data,
    data?.cross_sell_group,
    formStepsId,
    $enhancedLinkSerializer,
    $prismic
  );

  const form = await formStepsSerializer(formStepsId, data, context);

  return {
    form,
    content,
    confirmation,
  };
};
